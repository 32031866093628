import React, { FC } from 'react';
import styled from 'styled-components';

const ClimateNeutralTooltipWrapper = styled.div`
    color: ${({ theme }) => theme.colors.charcoal100};
    z-index: ${({ theme }) => theme.zIndexContentOverlay};
    font-size: 12px;
    line-height: 18px;
    padding: 16px 20px;
    width: 248px;
    height: 338px;
    border: 1px solid ${({ theme }) => theme.colors.charcoal30};
    background: ${({ theme }) => theme.colors.white};
    position: relative;
    &::before {
        background: ${({ theme }) => theme.colors.white};
        content: '';
        position: absolute;
        height: 17px;
        width: 17px;
        transform: rotate(45deg);
        bottom: 12px;
        right: -10px;
        border-right: 1px solid ${({ theme }) => theme.colors.charcoal30};
        border-top: 1px solid ${({ theme }) => theme.colors.charcoal30};
    }
`;

const ClimateNeutralTooltip: FC<{ text: string }> = ({ text }) => (
    <ClimateNeutralTooltipWrapper>{text}</ClimateNeutralTooltipWrapper>
);

export default ClimateNeutralTooltip;
